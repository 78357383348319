import { Box, IconButton, TextareaAutosize, Tooltip } from "@mui/material";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import { isMobile } from "react-device-detect";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import SendIcon from "@mui/icons-material/Send";
import { ImagePreview } from "./imagePreview";
import { FilePreview } from "./filePreview";
import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { $draft } from "store/chat/draft";
import { $chatRooms } from "store/chat/rooms";
import styled from "@emotion/styled";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { $textarea } from "store/chat/textarea";
import { $chatMessages } from "store/chat/messages";
import { $user } from "store/user";
import iziToast from "izitoast";
import EmojiPicker from "emoji-picker-react";

const TextareaAutosizeSt = styled(TextareaAutosize)`
  margin: 0 6px;
  border: 0;
  width: 100%;
  padding: 10px;
  line-height: 20px;
  resize: none;
  min-height: 54px;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-size: 16px;
  background: rgba(204, 204, 204, 0.25);
  border-radius: 10px;
`;

export const ChatTextArea = observer(
  ({ closeSelectedMessage, cancelEditing }) => {
    const formRef = useRef(null);
    const inputFile = useRef(null);
    const editMode = $textarea.isEditMode;

    const handleChangeTextArea = (e) => {
      const value = e.target.value;
      if (value.length === 0 && !editMode) {
        $draft.create("");
      }

      $textarea.setValue(value);
    };

    const attachFileClicked = () => {
      inputFile.current.click();
    };

    const handleUpload = (e) => {
      $textarea.clearAttachment();
      e.persist();
      const file = e.target.files[0];
      $textarea.setFile(file);

      if (file.type.includes("image")) {
        $textarea.setPreviewUrl(URL.createObjectURL(file));
      } else {
        $textarea.setUploadedFileName(file.name);
      }
    };

    const onEnterPress = (e) => {
      if (e.keyCode == 13 && !e.shiftKey) onSubmit(e);
    };

    const sendMessage = (e) => {
      e.preventDefault();

      if ($textarea.value.length > 0 || $textarea.file) {
        $textarea.setLoading(true);

        $textarea
          .sendMessage({
            message: $textarea.value,
            customer_id: $chatRooms.selectedCustomerId,
            file: $textarea.file,
            from_new_admin: true,
          })
          .then(() => {
            $textarea.clearAttachment();
            $draft.create("");
            $textarea.setValue("");
          })
          .catch((err) => {
            if (err.response.data && err.response.status === 422) {
              Object.values(err.response.data.errors).map((er) => {
                iziToast.warning({
                  message: er,
                });
              });
            }
          })
          .finally(() => $textarea.setLoading(false));
      }
    };

    const saveEditedMessage = (e) => {
      e.preventDefault();
      $textarea.setLoading(true);
      $chatMessages
        .editMessage({
          message: $textarea.value,
          file: $textarea.file,
        })
        .then(() => {
          $textarea.setValue("");
          closeSelectedMessage();
        })
        .finally(() => $textarea.setLoading(false));
    };

    function handlePaste(event) {
      const items = (event.clipboardData || {}).items;
      if (!items) return;

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === "file") {
          event.preventDefault();
          const file = item.getAsFile();
          $textarea.setFile(file);
          if (file.type.includes("image")) {
            $textarea.setUploadedFileName("");
            $textarea.setPreviewUrl(URL.createObjectURL(file));
          } else {
            $textarea.setUploadedFileName(file.name);
          }
        }
      }
    }

    const emojiClicked = (e) => {
      $textarea.appendValueNoSpace(e.emoji);
      $textarea.toggleEmoji();
    };

    const onSubmit = (e) => {
      if (!$textarea.isSendingDisabled) {
        editMode ? saveEditedMessage(e) : sendMessage(e);
      }
    };

    return (
      <>
        <form ref={formRef} onSubmit={onSubmit}>
          {$textarea.emojiVisible && (
            <EmojiPicker
              previewConfig={{
                showPreview: false,
              }}
              width={"100%"}
              onEmojiClick={emojiClicked}
            />
          )}
          <Box
            style={{
              borderTop: "1px solid #E7E8EA",
              padding: "5px",
              fontWeight: 500,
            }}
          >
            {editMode && (
              <Box
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                Редактирование сообщения:
              </Box>
            )}
            <div style={{ display: "flex"}}>
              <div style={{width:'min-content'}}>
                <input
                  key={$textarea.file}
                  onChange={(e) => handleUpload(e)}
                  style={{ display: "none" }}
                  ref={inputFile}
                  type="file"
                />
                <Tooltip placement="top" title="Прикрепить файл">
                  <IconButton onClick={attachFileClicked}>
                    <AttachFileOutlinedIcon style={{ color: "#ababab" }} />
                  </IconButton>
                </Tooltip>
                <IconButton onClick={() => $textarea.toggleEmoji()}>
                  <EmojiEmotionsOutlinedIcon style={{ color: "#ababab" }} />
                </IconButton>
              </div>

              <TextareaAutosizeSt
                disabled={$textarea.loading}
                onKeyDown={onEnterPress}
                autoFocus
                onPaste={handlePaste}
                value={$textarea.value}
                autoComplete="off"
                onChange={handleChangeTextArea}
                placeholder={`Напишите сообщение ${
                  !isMobile ? "и нажмите Enter" : ""
                }`}
              />

              <input type="submit" hidden />
              <div>
                {editMode ? (
                  <div className="flex">
                    <IconButton onClick={cancelEditing}>
                      <CloseOutlinedIcon style={{ color: "#f84646" }} />
                    </IconButton>
                    <IconButton disabled={$textarea.loading} onClick={onSubmit}>
                      <SaveAsOutlinedIcon
                        style={{
                          color: $textarea.loading ? "#ccc" : "#46aef8",
                        }}
                      />
                    </IconButton>
                  </div>
                ) : (
                  <IconButton
                    disabled={$textarea.isSendingDisabled}
                    onClick={onSubmit}
                  >
                    <SendIcon
                      style={{
                        color: $textarea.isSendingDisabled ? "#ccc" : "#46aef8",
                      }}
                    />
                  </IconButton>
                )}
              </div>
            </div>
          </Box>
        </form>

        {$textarea.previewUrl && (
          <ImagePreview
            previewUrl={$textarea.previewUrl}
            onDelete={() => $textarea.clearAttachment()}
          />
        )}

        {$textarea.uploadedFileName && (
          <FilePreview
            onDelete={() => $textarea.clearAttachment()}
            uploadedFileName={$textarea.uploadedFileName}
          />
        )}
      </>
    );
  }
);
