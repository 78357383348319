import React from "react";
import {Modal} from "ui-components/modal";
import {$modals} from "store/modals";
import {observer} from "mobx-react-lite";
import ReactJson from 'react-json-view';

const ReceiptDetailsModal = observer(() => {

  const params = $modals.params;

  return (
    <Modal
      title="Информация о чеке от Yookassa"
      subheader={!params && 'Ошибка'}
    >
      {params && <ReactJson
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        src={params}
        theme="rjv-default"
        collapsed={false}/>}
    </Modal>
  );
});

export default ReceiptDetailsModal;
