import {
  Box,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import {observer} from "mobx-react-lite";
import {InfiniteScroll} from "ui-components/scrollingTable";
import {parseUnixTime} from "lib/features";
import {ORDER_STATUS_ISSUED, orderStatuses} from "lib/orderStatuses";
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {Pagination} from "@mui/lab";
import {$transactions} from "../../store/transactions/transactions";
import {DELIVERY_PRODUCT_ID} from "../chat/customerInfo/order/returnProductButton";

const columns = [
  {
    id: "payment_id",
    label: "ID платежа",
  },
  {
    id: "transaction_type",
    label: "Тип",
  },
  {
    id: "object",
    label: "Объект",
  },
  {
    id: "store",
    label: "ПВЗ",
  },
  {
    id: "created_at",
    label: "Дата создания платежа",
  },
  {
    id: "has_paid",
    label: "Оплачен",
  },
  {
    id: "receipt_id",
    label: "Чек отправлен",
  },
  {
    id: "has_orf_receipt",
    label: "Чек сел в ОФД",
  },
  {
    id: "status",
    label: "Статус заказа",
  },
  {
    id: "actions",
    label: "Действия",
    minWidth: "112px",
    align: "right",
  },
];

const ReceiptsTable = observer(() => {

  const checkOfdReceipt = (receiptId) => {
    $transactions.checkOfdReceipt(receiptId);
  }

  const createReceipt = (receiptId) => {
    $transactions.sendOfdReceipt(receiptId);
  }

  const handleChangePaginate = (page) => {
    $transactions.setPage(page);
  }

  return (
    <InfiniteScroll>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{minWidth: column.minWidth}}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {$transactions.list.map((transaction, key) => (
            <TableRow hover tabIndex={-1} key={key}>
              <TableCell>{transaction.payment?.order_identity}</TableCell>
              <TableCell>{transaction.transaction_type == 'payment' ?
                <span style={{color: 'green'}}>приход</span> : <span style={{color: 'red'}}>возврат</span>
              }</TableCell>
              <TableCell>
                {(transaction.order_id && !transaction.product_id) && `Заказ №${transaction.order_id}`}
                {transaction.product_id && `${transaction.product_id === DELIVERY_PRODUCT_ID ? 'Доставка' : `Товар #${transaction.product_id}`}`}
              </TableCell>
              <TableCell>{transaction.order?.store.name}</TableCell>
              <TableCell>{parseUnixTime(transaction.created_at)}</TableCell>
              <TableCell align="center">
                {transaction.order?.paid == 2 && <CheckIcon style={{color: 'green', fontSize: '30px'}}/>}
              </TableCell>
              <TableCell align="center">
                {transaction.receipt_id && <CheckIcon style={{color: 'green', fontSize: '30px'}}/>}
                {(transaction.order?.status === ORDER_STATUS_ISSUED && !transaction.receipt_id) && (
                  <Tooltip title="Заказ уже выдан, а чек не был отправлен">
                    <ErrorOutlineOutlinedIcon style={{color: 'red', fontSize: '30px'}}/>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell align="center">
                {!!transaction.has_ofd_receipt && <CheckIcon style={{color: 'green', fontSize: '30px'}}/>}
                {!!transaction.has_ofd_receipt_error &&
                  <ErrorOutlineOutlinedIcon style={{color: 'red', fontSize: '30px'}}/>}
              </TableCell>
              <TableCell>
                {transaction.order?.status && <Chip
                  label={orderStatuses[transaction.order?.status]}
                  color={transaction.order?.status === ORDER_STATUS_ISSUED ? "success" : "primary"}
                  size="small"
                />}
              </TableCell>
              <TableCell align="right">
                <Tooltip title="">
                  <Button
                    disabled={(transaction.has_ofd_receipt && !transaction.has_ofd_receipt_error) || transaction.payment?.status !== 2}
                    onClick={() => createReceipt(transaction.id)}
                    sx={{mb: 1, minWidth: '120px'}} color="secondary" variant="contained" size="small">
                    Отправить чек
                  </Button>
                </Tooltip>
                <Button sx={{ml: 1, mb: 1, minWidth: '120px'}}
                        onClick={() => checkOfdReceipt(transaction.receipt_id)}
                        variant="contained"
                        disabled={!transaction.receipt_id}
                        size="small">
                  Проверить чек
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Box sx={{my: 4, display: 'flex', justifyContent: 'center'}}>
        <Pagination onChange={(e, page) => handleChangePaginate(page)}
                    variant="outlined"
                    page={$transactions.currentPage}
                    count={Math.ceil($transactions.totalCount / $transactions.limit)}
                    shape="rounded"
        />
      </Box>

    </InfiniteScroll>
  );
});

export default ReceiptsTable;
