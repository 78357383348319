import React, { Fragment, useEffect, useState } from "react";
import {
  Badge,
  Collapse,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import logo from "../assets/tbp_logo.svg";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import DonutLargeOutlinedIcon from "@mui/icons-material/DonutLargeOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AssistantOutlinedIcon from "@mui/icons-material/AssistantOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import SendToMobileOutlinedIcon from "@mui/icons-material/SendToMobileOutlined";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import TagIcon from "@mui/icons-material/Tag";
import { isMobile } from "react-device-detect";
import { $sidebar } from "../store/sidebar";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { find } from "lodash";
import { observer } from "mobx-react-lite";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { $user } from "../store/user";
import { $permissions } from "../store/permissions/permissions";
import bx24 from "./bx24.svg";
import { $bitrix } from "../store/bitrix";
import ThreePOutlinedIcon from "@mui/icons-material/ThreePOutlined";

const ListItemButtonSt = styled(ListItemButton)`
  width: 260px;
`;

export const MenuItems = observer(() => {
  const list = [
    {
      id: 2,
      label: "Главная",
      path: "/dashboard",
      icon: <HomeOutlinedIcon />,
    },
    {
      id: 6,
      label: "Диалоги",
      icon: <ForumOutlinedIcon />,
      children: [
        {
          path: "/chat",
          label: "Сообщения",
          icon: <SupportAgentIcon />,
        },
        {
          path: "/suggestions",
          label: "Шаблоны",
          icon: <AssistantOutlinedIcon />,
        },
        {
          path: "/chat/stats",
          label: "Статистика",
          icon: <QueryStatsOutlinedIcon />,
        },
      ],
    },
    {
      id: 10,
      label: "Чеки заказов",
      path: "/receipts",
      icon: <ReceiptLongOutlinedIcon />,
    },
    {
      id: 9,
      label: "Отзывы",
      path: "/reviews",
      icon: <ThreePOutlinedIcon />,
    },
    {
      id: 3,
      label: "Управление",
      icon: <ManageAccountsOutlinedIcon />,
      children: [
        {
          label: "Пользователи",
          path: "/users",
          icon: <GroupOutlinedIcon />,
        },
        {
          label: "Роли",
          path: "/roles",
          icon: <BadgeOutlinedIcon />,
        },
        {
          label: "Доступы в разделы",
          path: "/permissions",
          icon: <LockOutlinedIcon />,
        },
      ],
    },
    {
      id: 8,
      label: "Уведомления",
      icon: <SendOutlinedIcon />,
      children: [
        {
          path: "/notifications",
          label: "Все уведомления",
          icon: <SpeakerNotesOutlinedIcon />,
        },
        {
          path: "/notificationsForm",
          label: "Создать рассылку",
          icon: <SendToMobileOutlinedIcon />,
        },
      ],
    },
    {
      id: 4,
      label: "Отчеты",
      icon: <DonutLargeOutlinedIcon />,
      children: [
        {
          label: "Жалобы покупателей",
          path: "/complaints",
          icon: <AnnouncementOutlinedIcon />,
        },
      ],
    },
    {
      id: 5,
      label: "Товары",
      icon: <CategoryOutlinedIcon />,
      // counter: 2,
      children: [
        {
          path: "/remarks",
          label: "Ошибки в товарах",
          // counter: 2,
          icon: <ErrorOutlineOutlinedIcon />,
        },
        {
          path: "/promo",
          label: "Акционные товары",
          icon: <CategoryOutlinedIcon />,
        },
        {
          path: "/tags",
          label: "Рекомендованные",
          icon: <TagIcon />,
        },
      ],
    },
    {
      id: 7,
      label: "Покупатели",
      path: "/customers",
      icon: <GroupOutlinedIcon />,
    },
  ];

  const [menuItems, setMenuItems] = useState(list);

  useEffect(() => {
    let filtered = menuItems.map((item) => {
      item.allowed = !item.path
        ? true
        : $permissions.isAllowedForRole(item.path);

      if (item.children) {
        item.children.map(
          (subItem) =>
            (subItem.allowed = $permissions.isAllowedForRole(subItem.path))
        );
        item.allowed = item.children.filter((el) => el.allowed).length !== 0;
      }
      return item;
    });

    setMenuItems(filtered);
  }, [$user.role]);

  const location = useLocation();
  const navigate = useNavigate();

  const [expandedMenuItem, setMenuItem] = useState(null);

  const menuItemClicked = (item) => {
    if (!item.path) {
      setMenuItem(expandedMenuItem === item.id ? null : item.id);
    } else {
      isMobile && $sidebar.hide();
      navigate(item.path);
    }
  };

  const logoutClicked = () => {
    $user.logout().then(() => navigate("/login", { replace: true }));
  };

  //если активная страница находится в дочернем списке, раскрываем список
  useEffect(() => {
    menuItems.forEach((el) => {
      let parent = find(el.children, ["path", location.pathname]);
      if (parent) setMenuItem(el.id);
    });
  }, []);

  return (
    <>
      <a href="/" style={{ textDecoration: "none" }}>
        <div
          style={{
            display: "flex",
            padding: "11px 16px",
            cursor: "pointer",
            width: "260px",
            borderBottom: "1px solid #e4e4e4",
          }}
        >
          <img
            alt="logo"
            style={{ width: "30px", marginTop: "-3px", marginRight: "10px" }}
            src={logo}
          />
          <div
            style={{
              paddingTop: "2px",
              fontSize: isMobile ? "14px" : "16px",
              fontWeight: 400,
              color: "#000",
            }}
          >
            ТОВАР БЕЗ ПЕРЕПЛАТ
          </div>
        </div>
      </a>

      <div>
        {menuItems.map(
          (menuItem, key) =>
            menuItem.allowed && (
              <Fragment key={key}>
                <ListItemButtonSt
                  style={menuItem.style}
                  disabled={!menuItem.allowed}
                  key={key}
                  selected={menuItem.path === location.pathname}
                  divider
                  onClick={() => menuItemClicked(menuItem)}
                >
                  <ListItemIcon>{menuItem.icon}</ListItemIcon>
                  <ListItemText primary={menuItem.label} />
                  <div style={{ paddingRight: "14px" }}>
                    <Badge
                      className="badge"
                      color="secondary"
                      badgeContent={menuItem.counter}
                      max={99}
                    />
                  </div>
                  {menuItem.children &&
                    (expandedMenuItem === menuItem.id ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    ))}
                </ListItemButtonSt>
                {menuItem.children && (
                  <Collapse
                    in={expandedMenuItem === menuItem.id}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {menuItem.children.map(
                        (subItem, key) =>
                          subItem.allowed && (
                            <ListItemButtonSt
                              key={key}
                              sx={{ pl: 4 }}
                              disabled={!subItem.allowed}
                              divider
                              selected={subItem.path === location.pathname}
                              onClick={() => menuItemClicked(subItem)}
                            >
                              <ListItemIcon>{subItem.icon}</ListItemIcon>
                              <ListItemText primary={subItem.label} />
                              <div style={{ paddingRight: "6px" }}>
                                <Badge
                                  className="badge"
                                  color="secondary"
                                  badgeContent={subItem.counter}
                                  max={99}
                                />
                              </div>
                            </ListItemButtonSt>
                          )
                      )}
                    </List>
                  </Collapse>
                )}
              </Fragment>
            )
        )}
      </div>

      <div style={{ marginTop: "auto" }}>
        <Divider />
        <ListItemButton
          onClick={() => !$bitrix.isAuthenticated && $bitrix.authClicked()}
        >
          <ListItemIcon>
            <img style={{ width: "28px" }} src={bx24} alt="" />
          </ListItemIcon>
          <ListItemText
            primary={
              $bitrix.isAuthenticated
                ? "Битрикс24 подключен"
                : "Подключить Битрикс24"
            }
          />
        </ListItemButton>

        <Divider />
        <ListItemButton onClick={logoutClicked}>
          <ListItemIcon>
            <LogoutOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Выход" />
        </ListItemButton>
      </div>
    </>
  );
});
