import React from "react";
import { $modals } from "store/modals";
import { observer } from "mobx-react-lite";
import NewCategoryModal from "pages/suggestions/modals/newCategoryModal";
import DeleteCategoryModal from "pages/suggestions/modals/deleteCategoryModal";
import EditCategoryModal from "pages/suggestions/modals/editCategoryModal";
import NewSuggestionModal from "pages/suggestions/modals/newSuggestionModal";
import DeleteSuggestionModal from "pages/suggestions/modals/deleteSuggestionModal";
import EditSuggestionModal from "pages/suggestions/modals/editSuggestionModal";
import NewPromoModal from "pages/promo/modals/newPromoModal";
import EditPromoModal from "pages/promo/modals/editPromoModal";
import DeletePromoModal from "pages/promo/modals/deletePromoModal";
import DeleteCustomerModal from "pages/customers/deleteModal";
import BlockCustomerModal from "pages/customers/blockModal";

import CreateUserModal from "pages/users/modals/createModal";
import DeleteUserModal from "pages/users/modals/deleteModal";
import EditUserModal from "pages/users/modals/editModal";
import NewTagModal from "pages/tags/modals/newTagModal";
import DeleteTagModal from "pages/tags/modals/deleteTagModal";
import EditTagModal from "pages/tags/modals/editTagModal";
import AddProductToTagModal from "pages/tags/modals/addProductToTagModal";
import DeleteProductFromTagModal from "pages/tags/modals/deleteProductFromTagModal";
import UploadTextfileToTagModal from "pages/tags/modals/uploadTextfileToTagModal";
import CreateRoleModal from "../../pages/roles/modals/createModal";
import DeleteRoleModal from "../../pages/roles/modals/deleteModal";
import EditRoleModal from "../../pages/roles/modals/editModal";
import CheckPaymentModal from "../../pages/chat/customerInfo/modals/checkPaymentModal";
import DeleteProductFromOrderModal from "../../pages/chat/customerInfo/modals/deleteProductFromOrderModal";
import ReturnProductFromOrderModal from "../../pages/chat/customerInfo/modals/returnProductFromOrderModal";
import CheckEntityPaymentModal from "../../pages/chat/customerInfo/modals/checkEntityPaymentModal";
import RestoreOrderModal from "../../pages/chat/customerInfo/modals/restoreOrderModal";
import DeleteReviewModal from "../../pages/reviews/deleteReviewModal";
import ReturnOrderToShopModal from "../../pages/chat/customerInfo/modals/returnOrderToShopModal";
import ReceiptDetailsModal from "../../pages/receipts/receiptDetailModal";

export const DELETE_CUSTOMER_MODAL = "DELETE_CUSTOMER_MODAL";
export const BLOCK_CUSTOMER_MODAL = "BLOCK_CUSTOMER_MODAL";

export const NEW_CATEGORY_MODAL = "NEW_CATEGORY_MODAL";
export const DELETE_CATEGORY_MODAL = "DELETE_CATEGORY_MODAL";
export const EDIT_CATEGORY_MODAL = "EDIT_CATEGORY_MODAL";

export const NEW_SUGGESTION_MODAL = "NEW_SUGGESTION_MODAL";
export const DELETE_SUGGESTION_MODAL = "DELETE_SUGGESTION_MODAL";
export const EDIT_SUGGESTION_MODAL = "EDIT_SUGGESTION_MODAL";

export const NEW_PROMO_MODAL = "NEW_PROMO_MODAL";
export const EDIT_PROMO_MODAL = "EDIT_PROMO_MODAL";
export const DELETE_PROMO_MODAL = "DELETE_PROMO_MODAL";

export const NEW_USER_MODAL = "NEW_USER_MODAL";
export const DELETE_USER_MODAL = "DELETE_USER_MODAL";
export const EDIT_USER_MODAL = "EDIT_USER_MODAL";

export const NEW_TAG_MODAL = "NEW_TAG_MODAL";
export const DELETE_TAG_MODAL = "DELETE_TAG_MODAL";
export const EDIT_TAG_MODAL = "EDIT_TAG_MODAL";
export const DELETE_PRODUCT_FROM_TAG_MODAL = "DELETE_PRODUCT_FROM_TAG_MODAL";
export const ADD_PRODUCT_TO_TAG_MODAL = "ADD_PRODUCT_TO_TAG_MODAL";
export const UPLOAD_TXTFILE_TO_TAG_MODAL = "UPLOAD_TXTFILE_TO_TAG_MODAL";

export const NEW_ROLE_MODAL = "NEW_ROLE_MODAL";
export const DELETE_ROLE_MODAL = "DELETE_ROLE_MODAL";
export const EDIT_ROLE_MODAL = "EDIT_ROLE_MODAL";

export const CHECK_PAYMENT_MODAL = "CHECK_PAYMENT_MODAL";

export const DELETE_PRODUCT_FROM_ORDER_MODAL =
  "DELETE_PRODUCT_FROM_ORDER_MODAL";
export const RETURN_PRODUCT_FROM_ORDER_MODAL =
  "RETURN_PRODUCT_FROM_ORDER_MODAL";

export const CHECK_ENTITY_PAYMENT_MODAL = "CHECK_ENTITY_PAYMENT_MODAL";

export const RESTORE_ORDER_MODAL = "RESTORE_ORDER_MODAL";

export const RETURN_ORDER_TO_SHOP_MODAL = "RETURN_ORDER_TO_SHOP_MODAL";

export const DELETE_REVIEW_MODAL = "DELETE_REVIEW_MODAL"

export const RECEIPT_DETAILS_MODAL = "RECEIPT_DETAILS_MODAL";

//При создании модалок добавлять сюда
const modals = {
  [DELETE_CUSTOMER_MODAL]: <DeleteCustomerModal />,
  [BLOCK_CUSTOMER_MODAL]: <BlockCustomerModal />,

  [NEW_CATEGORY_MODAL]: <NewCategoryModal />,
  [DELETE_CATEGORY_MODAL]: <DeleteCategoryModal />,
  [EDIT_CATEGORY_MODAL]: <EditCategoryModal />,

  [NEW_SUGGESTION_MODAL]: <NewSuggestionModal />,
  [DELETE_SUGGESTION_MODAL]: <DeleteSuggestionModal />,
  [EDIT_SUGGESTION_MODAL]: <EditSuggestionModal />,

  [NEW_PROMO_MODAL]: <NewPromoModal />,
  [EDIT_PROMO_MODAL]: <EditPromoModal />,
  [DELETE_PROMO_MODAL]: <DeletePromoModal />,

  [NEW_USER_MODAL]: <CreateUserModal />,
  [DELETE_USER_MODAL]: <DeleteUserModal />,
  [EDIT_USER_MODAL]: <EditUserModal />,

  [NEW_TAG_MODAL]: <NewTagModal />,
  [DELETE_TAG_MODAL]: <DeleteTagModal />,
  [EDIT_TAG_MODAL]: <EditTagModal />,
  [ADD_PRODUCT_TO_TAG_MODAL]: <AddProductToTagModal />,
  [DELETE_PRODUCT_FROM_TAG_MODAL]: <DeleteProductFromTagModal />,
  [UPLOAD_TXTFILE_TO_TAG_MODAL]: <UploadTextfileToTagModal />,

  [NEW_ROLE_MODAL]: <CreateRoleModal />,
  [DELETE_ROLE_MODAL]: <DeleteRoleModal />,
  [EDIT_ROLE_MODAL]: <EditRoleModal />,

  [CHECK_PAYMENT_MODAL]: <CheckPaymentModal />,

  [DELETE_PRODUCT_FROM_ORDER_MODAL]: <DeleteProductFromOrderModal />,

  [RETURN_PRODUCT_FROM_ORDER_MODAL]: <ReturnProductFromOrderModal />,

  [CHECK_ENTITY_PAYMENT_MODAL]: <CheckEntityPaymentModal />,

  [RESTORE_ORDER_MODAL]: <RestoreOrderModal />,

  [RETURN_ORDER_TO_SHOP_MODAL]: <ReturnOrderToShopModal />,

  [DELETE_REVIEW_MODAL]: <DeleteReviewModal />,

  [RECEIPT_DETAILS_MODAL]: <ReceiptDetailsModal />,
};

export const RenderModal = observer(() => {
  let modal;
  if ($modals.name) {
    modal = modals[$modals.name];

    if (!modal) {
      console.error("Modal not found! Check src/modals.js");
      $modals.close();
    }
  }

  return modal || <></>;
});
