import React from "react";
import {Box, CircularProgress, FormControl, TextField} from "@mui/material";
import {Modal} from "ui-components/modal";
import {useState} from "react";
import {$modals} from "store/modals";
import {observer} from "mobx-react-lite";
import {Alert, LoadingButton} from "@mui/lab";
import {$chatOrders} from "store/chat/orders";
import {$bitrix} from "store/bitrix";
import iziToast from "izitoast";
import Barcode from "react-jsbarcode";
import {DELIVERY_PRODUCT_ID} from "../order/returnProductButton";
import {$transactions} from "store/transactions/transactions";

const ReturnProductFromOrderModal = observer(() => {
  const product = $modals.params.product;
  const orderId = $modals.params.orderId;
  const shopId = $modals.params.shopId;
  const barcode = $modals.params.orderBarcode;
  const paymentId = $modals.params.paymentId;

  const [message, setMessage] = useState(
    `Здравствуйте, покупателю заказа №${orderId} разрешен возврат на "${product.name}" в количестве ${product.pivot.amount} шт. сразу после выкупа заказа.`
  );

  const [btnLoading, setBtnLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    const svgElement = document.getElementById("barcode");
    const svgString = new XMLSerializer().serializeToString(svgElement);
    const blob = new Blob([svgString], {type: "image/svg+xml"});
    const formData = new FormData();
    formData.append("attach", blob, "my-svg-file.svg");
    formData.append("message", message);
    formData.append("shop_id", shopId);

    $chatOrders
      .returnProductFromOrder(formData, orderId)
      .then(() => {
        $modals.close();
        iziToast.success({
          message: "Сообщение отправлено",
        });
      })
      .finally(() => setBtnLoading(false));
  };

  const createReturnTransaction = () => {
    $transactions
      .createTransaction({
        orderId: orderId,
        productId: product.id,
        transactionType: 'refund',
        paymentId: paymentId,
      })
      .then(() => {
        $modals.close();
        iziToast.success({
          message: "Транзакция создана",
        });
      })
      .finally(() => setBtnLoading(false));
  }

  const defaultProductContent = (
    !$bitrix.isAuthenticated ? (
      <Alert severity="error">
        {$bitrix.loader ? (
          <>
            Пожалуйста, подождите ...
          </>
        ) : (
          <>
           <span
             style={{
               color: "#206491",
               textDecoration: "underline",
               cursor: "pointer",
             }}
             onClick={() => $bitrix.authClicked()}
           >
            Авторизуйтесь
          </span>{" "}
            через Битрикс24 для продолжения.
          </>
        )}
      </Alert>
    ) : (
      <form onSubmit={onSubmit}>
        <FormControl sx={{mb: 2}} fullWidth>
          <TextField
            helperText="Сообщение и ссылка на ШК будет отправлено магазину в Битрикс24."
            label="Сообщение"
            rows={4}
            multiline
            required
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
        </FormControl>

        {barcode.length && (
          <div id="barcode">
            <Barcode
              value={barcode}
              options={{format: "code128"}}
              renderer={"svg"}
            />
          </div>
        )}

        <Box sx={{textAlign: "right"}}>
          <LoadingButton
            type="submit"
            loading={btnLoading}
            variant="contained"
          >
            Отправить
          </LoadingButton>
        </Box>
      </form>
    )
  );

  const deliveryProductContent = (
    <>
      <div>В разделе "Чеки заказов" будет создана транзакция для возврата.</div>
      <Box sx={{textAlign: "right", mt:2}}>
        <LoadingButton
          onClick={createReturnTransaction}
          loading={btnLoading}
          variant="contained"
        >
          Подтвердить
        </LoadingButton>
      </Box>
    </>
  )

  return (
    <Modal title="Возврат" subheader={`Услуга: ${product.name}`}>
      {product.id === DELIVERY_PRODUCT_ID ? deliveryProductContent : defaultProductContent}
    </Modal>
  );
});

export default ReturnProductFromOrderModal;
