import { types } from "mobx-state-tree";
import { flow } from "mobx";
import { api, v1Endpoint, v3Endpoint } from "api";
import { $chatRooms } from "./rooms";
import { union } from "lodash";
import iziToast from "izitoast";

export const $chatOrders = types
  .model({
    user: types.frozen(null),
    loading: types.optional(types.boolean, false),
    page: types.optional(types.number, 1),
    hasMore: types.optional(types.boolean, false),
    orders: types.frozen([]),
    activeOrders: types.frozen(null),
  })
  .views((self) => ({
    get isPurchasesBlocked() {
      return self.user?.status === 5;
    },
    get isImportant() {
      return self.user ? !!self.user.chat?.is_important : false;
    },
  }))
  .actions((self) => ({
    getOrders: flow(function* () {
      try {
        self.setLoading(true);

        if(self.page === 1) {
          self.setOrders([]);
          self.setUser(null);
          self.setActiveOrders(null);
        }

        const res = yield api(v3Endpoint)
          .get(`orders/${$chatRooms.selectedCustomerId}`, {
            params: {
              page: self.page,
            },
          })
          .then((res) => res.data);

        self.setHasMore(res.hasMoreOrders);
        self.setUser(res.user);
        self.setActiveOrders(res.active_orders);
        self.setOrders(self.page > 1 ? union(self.orders, res.orders) : res.orders);
      } catch (err) {
        throw err;
      } finally {
        self.setLoading(false);
      }
    }),
    cancelOrder: flow(function* (orderId) {
      return yield api(v3Endpoint)
        .delete(`orders/${orderId}`)
        .then(() => {
          iziToast.success({
            message: "Обработка. Запрос отправлен в 1С",
            timeout: 1000,
            progressBar: false
          });
        });
    }),
    checkPayment: flow(function* (orderId) {
      try {
        yield api(v1Endpoint)
          .get(`admin/orders/payment-check/${orderId}`)
          .then((res) => res.data);
      } catch (err) {
        throw err;
      }
    }),
    restoreOrder: flow(function* (orderId, status) {
      try {
        return yield api(v3Endpoint)
          .post(`orders/${orderId}`, {
            status: status
          })
          .then((res) => res.data)
          .then(() => {
            // $chatOrders.getOrders()
            iziToast.success({
              message: 'Запрос отправлен в 1С',
            });
          });
      } catch (err) {
        throw err;
      }
    }),
    returnToShopOrder: flow(function* (orderId) {
      try {
        return yield api(v3Endpoint)
          .post(`orders/${orderId}/returnToShop`)
          .then((res) => res.data)
          .then(() => {
            // $chatOrders.getOrders()
            iziToast.success({
              message: 'Запрос отправлен в 1С',
            });
          });
      } catch (err) {
        throw err;
      }
    }),
    deleteProductFromOrder: flow(function* (product, count) {
      try {
        yield api(v3Endpoint)
          .put(`orders/${product.pivot.order_id}/removeProduct`, {
            count: count,
            vendor_code: product.id,
          })
          .then((res) => res.data);
      } catch (err) {
        throw err;
      }
    }),
    returnProductFromOrder: flow(function* (formData, orderId) {
      try {
        yield api(v3Endpoint)
          .post(`orders/${orderId}/returnProduct`, formData)
          .then((res) => res.data);
      } catch (err) {
        throw err;
      }
    }),
    checkEntityPayment: flow(function* (orderId) {
      try {
        return yield api(v3Endpoint)
          .get(`orders/${orderId}/checkEntityPayment`)
          .then((res) => res.data);
      } catch (err) {
        throw err;
      }
    }),
    toggleImportant: flow(function* () {
      try {
        const res = yield api(v3Endpoint)
          .post(`consultant/${self.user.id}/toggleImportant`)
          .then((res) => res.data);

        $chatRooms.setImportantCount(
          res.important_status
            ? $chatRooms.importantCount + 1
            : $chatRooms.importantCount - 1
        );
        iziToast.success({
          message: res.important_status
            ? `Чат отмечен как важный`
            : `Отметка "Важный чат" удалена`,
        });

        $chatRooms.updateRoomProperty(
          "is_important",
          res.important_status,
          self.user.id
        );
      } catch (err) {
        throw err;
      }
    }),
    paginate() {
      self.page++;
      self.getOrders();
    },
    setHasMore(value) {
      self.hasMore = value;
    },
    resetPaginate() {
      self.offset = 0;
    },
    setOrders(value) {
      self.orders = value;
    },
    setLoading(value) {
      self.loading = value;
    },
    setUser(value) {
      self.user = value;
    },
    setActiveOrders(value) {
      self.activeOrders = value;
    },
  }))
  .create();
