import "./App.scss";
import {
  Backdrop,
  CircularProgress,
  CssBaseline,
  StyledEngineProvider,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import theme from "themes";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { $user } from "./store/user";
import { $spinner } from "./store/spinner";
import { RenderModal } from "./ui-components/modal/modals";
import { RenderSnackbar } from "./ui-components/snackbar";
import MainLayout from "./layout";
import Login from "./pages/login";
import { $common } from "./store/common";
import { useLocation } from "react-router-dom";

const App = observer(() => {
  const location = useLocation();

  useEffect(() => {
    $common.setCurrentRoute(location.pathname);
  }, [location.pathname]);

  return (
    <StyledEngineProvider injectFirst>
      <div style={{ position: "fixed", zIndex: 9999, fontSize: 12 }}>
        v0.0.30
      </div>
      <ThemeProvider theme={theme}>
        {$user.isAuthorized ? <MainLayout /> : <Login />}
        <CssBaseline />
        <RenderModal />
        <RenderSnackbar />
        <Backdrop sx={{ color: "#fff", zIndex: 10000 }} open={$spinner.visible}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});

export default App;
