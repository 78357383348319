import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import { $sidebar } from "store/sidebar";
import ReceiptsTable from "./table";
import {InputSearch} from "ui-components/inputSearch";
import {debounce} from "lodash";
import {$transactions} from "../../store/transactions/transactions";

const Receipts = observer(() => {
  useEffect(() => {
    $sidebar.setPageTitle("Чеки заказов");
    $transactions.fetchList();
  }, []);

  const handleSearch = debounce((e) => {
    $transactions.setSearchValue(e.target.value);//TODO
  }, 500);

  return (
    <>
      <Typography
        variant="h4"
        component="div"
        sx={{ mb: 2 }}
      >
        Чеки заказов
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Stack spacing={2} direction="row">
          <InputSearch
            onChange={handleSearch}
            placeholder="ID платежа или ID заказа/товара"
          />
          <FormControl>
            <FormLabel id="transaction_type">Тип транзакции</FormLabel>
            <RadioGroup
              onChange={e => $transactions.setTransactionType(e.target.value)}
              row
              aria-labelledby="transaction_type"
              name="transaction_type"
            >
              <FormControlLabel checked={!$transactions.transactionType} value="" control={<Radio />} label="Все" />
              <FormControlLabel checked={$transactions.transactionType === 'payment'} value="payment" control={<Radio />} label="Приход" />
              <FormControlLabel checked={$transactions.transactionType === 'refund'} value="refund" control={<Radio />} label="Возврат" />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Box>
      <ReceiptsTable />
    </>
  );
});

export default Receipts;
