import { types } from "mobx-state-tree";
import { api, consultantEndpoint} from "../api";
import { flow } from "mobx";
import { $user } from "./user";
import iziToast from "izitoast";

export const $bitrix = types
  .model("Bitrix", {
    isAuthenticated: types.optional(types.boolean, false),
    loader:types.optional(types.boolean, false),
  })
  .views((self) => ({}))
  .actions((self) => ({
    checkAuth: flow(function* () {
      try {
        const res = yield api(consultantEndpoint)
          .get("bitrix-authenticated")
          .then((res) => res.data);
        self.setIsAuthenticated(res.status);
        return res;
      } catch (err) {
        throw err;
      }
    }),
    setIsAuthenticated(value) {
      self.isAuthenticated = value;
    },
    setLoader(value) {
      self.loader = value;
    },
    authClicked() {
      self.setLoader(true);
      window
        .open(
          `https://tkvprok.bitrix24.ru/oauth/authorize/?client_id=local.6343aa62248fb9.91041713&state=${$user.profile.user_id}`,
          "_blank"
        )
        .focus();

      setTimeout(() => {
        self.checkAuth().then((res) => {
          if (res.status) {
            iziToast.success({
              message: "Битрикс24 подключен",
            });
          } else {
            iziToast.error({
              message: "Ошибка подключения Битрикс24",
            });
          }
          self.setLoader(false);
        });
      }, 2000);
    },
  }))
  .create();
