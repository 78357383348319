import React, {useState} from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  CardHeader,
  Chip,
  Grid,
  Avatar,
  IconButton,
  LinearProgress,
  Skeleton, Tooltip
} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {observer} from "mobx-react-lite";
import {isDesktop, isMobile} from "react-device-detect";
import {$chatRooms} from "store/chat/rooms";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import DesktopMacOutlinedIcon from "@mui/icons-material/DesktopMacOutlined";
import {OrderCard} from "./order/orderCard";
import {$chatOrders} from "store/chat/orders";
import {EarlierMessagesBtn} from "../messages";
import {$modals} from "store/modals";
import {
  BLOCK_CUSTOMER_MODAL,
  RESTORE_ORDER_MODAL, RETURN_ORDER_TO_SHOP_MODAL,
} from "ui-components/modal/modals";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {ChatMessagesHeader} from "../components/chatMessagesHeader";
import iziToast from "izitoast";

const Container = styled.div`
    width: 37%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    border-left: 1px solid #e7e8ea;
`;

const CardsWrapper = styled.div`
    overflow-y: scroll;
    height: 100%;
`;

const NoOrders = styled.div`
    text-align: center;
    padding: 20px;
    opacity: 0.7;
`;

const printDeviceIcon = (deviceType) => {
  if (deviceType === 0) {
    return <AppleIcon/>;
  } else if (deviceType === 1) {
    return <AndroidIcon/>;
  } else if (deviceType === 2) {
    return <DesktopMacOutlinedIcon/>;
  } else {
    return null;
  }
};

export const CustomerInfo = observer(() => {
  const [userInfoVisible, setUserInfoVisible] = useState(isDesktop);
  const [cancelBtnLoadingId, setCancelBtnLoadingId] = useState(null);

  const blockPurchasesClicked = () => {
    $modals.show(BLOCK_CUSTOMER_MODAL, $chatOrders.user);
  };

  const restoreOrderClicked = (orderId) => {
    $modals.show(RESTORE_ORDER_MODAL, orderId);
  };

  const cancelOrderClicked = (orderId) => {
    setCancelBtnLoadingId(orderId);
    $chatOrders.cancelOrder(orderId).finally(() => setCancelBtnLoadingId(null));
  };

  const returnToShopClicked = (orderId) => {
    $modals.show(RETURN_ORDER_TO_SHOP_MODAL, orderId);
  };

  const handleCopy = (mainOrderId) => {
    navigator.clipboard.writeText(mainOrderId)
      .then(() => {
        iziToast.success({
          message: "ID скопирован",
          timeout: 1000,
          progressBar: false
        });
      })
      .catch(err => {
        console.error('Ошибка при копировании: ', err);
      });
  };

  return (
    <Container>
      {isMobile && (
        <ChatMessagesHeader
          backArrowClicked={() => $chatRooms.toggleShowUserInfo()}
        >
          {isMobile && (
            <IconButton
              onClick={() => setUserInfoVisible(!userInfoVisible)}
              edge="start"
              color="inherit"
            >
              <InfoOutlinedIcon className="icon"/>
            </IconButton>
          )}
        </ChatMessagesHeader>
      )}

      <Grid container sx={{minHeight: isDesktop ? "130px" : "auto", borderBottom: '1px solid rgb(231, 232, 234)'}}>
        {(userInfoVisible && $chatRooms.selectedCustomerId) && (
          <>
            <Grid sx={{p: 1}} item xs={12} md={7}>
              <div
                className="flex-vertical-center"
                style={{height: "100%"}}
              >
                <CardHeader
                  sx={{padding: "0 0 0 12px", mb: isDesktop ? 0 : 2}}
                  avatar={
                    $chatOrders.user ? (
                      <Avatar
                        src={$chatOrders.user?.avatar}
                        style={{width: 84, height: 84}}
                      />
                    ) : (
                      <Skeleton variant="circular" width={84} height={84}/>
                    )
                  }

                  title={$chatOrders.user?.name
                    ?
                    (
                      <Tooltip
                        title={
                          <React.Fragment>
                            {$chatOrders.user?.id && <div>ID: {$chatOrders.user?.id}</div>}
                            {$chatOrders.user?.birth_date && <div>Зарегистрирован: {$chatOrders.user?.created_at}</div>}
                            {$chatOrders.user?.age && <div>Возраст: {$chatOrders.user?.age}</div>}
                            {$chatOrders.user?.last_ip && <div>IP: {$chatOrders.user?.last_ip}</div>}
                            {$chatOrders.user?.all_orders_count && <div>Создано заказов: {$chatOrders.user?.all_orders_count}</div>}
                            {$chatOrders.user.deleted_orders_count && <div>Удалено заказов: {$chatOrders.user.deleted_orders_count}</div>}
                          </React.Fragment>
                        }
                      >
                        <Box sx={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}>{$chatOrders.user?.name}</Box>
                      </Tooltip>
                    )
                    // <>{$chatOrders.user?.name} <InfoOutlinedIcon fontSize="20px"  sx={{paddingTop: '0px'}}/></>
                    :
                    <Skeleton variant="text" sx={{fontSize: '1.5rem'}}/>
                  }
                  subheader={
                    <>
                      <div>{$chatOrders.user?.phone || <Skeleton variant="text" sx={{fontSize: '1rem'}}/>}</div>
                      <div>{$chatOrders.user?.city || <Skeleton variant="text" sx={{fontSize: '1rem'}}/>}</div>
                      <Box sx={{mt: 1}}>
                        {$chatOrders.user ?
                          (
                            <Chip
                              sx={{borderRadius: "6px"}}
                              variant="outlined"
                              color="secondary"
                              icon={printDeviceIcon(
                                $chatOrders.user?.device_type
                              )}
                              size="small"
                              label={$chatOrders.user?.app_version || "версия не указана"}
                            />
                          ) : (
                            <Skeleton variant="rounded" width={60} height={24}/>
                          )}
                      </Box>
                    </>
                  }
                />
              </div>
            </Grid>
            <Grid sx={{p: 2}} item xs={12} md={5}>
              <Box className="flex-end" sx={{mb: 1}}>
                {$chatOrders.user ?
                  (
                    <Button
                      size="small"
                      variant="contained"
                      color={$chatOrders.isPurchasesBlocked ? "error" : "primary"}
                      sx={{minWidth: "200px"}}
                      onClick={blockPurchasesClicked}
                    >
                      {$chatOrders.isPurchasesBlocked
                        ? "Снять ограничение"
                        : "Ограничить способ оплаты"}{" "}
                    </Button>
                  ) : (
                    <Skeleton variant="rounded" width={200} height={30}/>
                  )
                }
              </Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "12px",
                  fontSize: "16px",
                }}
              >
                {$chatOrders.activeOrders !== null ?
                  (
                    <Chip label={
                      <>
                        активные заказы: {" "}
                        <span style={{color: "green", paddingLeft: "4px"}}>
                            {$chatOrders.activeOrders}
                          </span>
                        /5
                      </>
                    }/>
                  ) : (
                    <Skeleton variant="rounded" width={221} height={19}/>
                  )
                }
              </div>
            </Grid>
          </>
        )}
      </Grid>

      <CardsWrapper>
        {($chatOrders.orders.length === 0 && !$chatOrders.loading && $chatRooms.selectedCustomerId) ? (
          <NoOrders>Нет заказов</NoOrders>
        ) : ($chatOrders.orders
            .map((order, key) => (
              <div
                style={{
                  borderLeft: '4px solid #1e88e6',
                  borderRadius: '3px',
                  padding: '4px',
                  background: 'rgba(204,204,204,0.53)',
                  marginBottom: '24px'
                }}
                key={key}
              >
                <div
                  style={{
                    padding: '8px 11px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    borderRadius: '5px',
                    backgroundColor: '#e2f2fd',
                    marginBottom: '4px',
                  }}
                >
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{opacity: '.6', paddingRight: '4px'}}>ОСНОВНОЙ ID:</span>
                    <span style={{fontWeight: '500'}}>{order.main_order_id}</span>
                    <ContentCopyIcon
                      opacity=".6"
                      cursor="small"
                      fontSize="small"
                      style={{marginLeft: '8px'}}
                      onClick={() => handleCopy(order.main_order_id)}
                    />
                  </div>
                  {order.orders.length > 1 && (
                    <div>
                      <span style={{opacity: '.6', paddingRight: '4px'}}>ЗАКАЗ РАЗДЕЛЕН НА:</span>
                      {order.orders.length}
                    </div>
                  )}
                </div>
                {order.orders.map((o, k) => (
                  <OrderCard
                    onReturnToShopClicked={returnToShopClicked}
                    onCancelClicked={cancelOrderClicked}
                    cancelBtnLoading={cancelBtnLoadingId === o.id}
                    onRestoreOrderClicked={restoreOrderClicked}
                    order={o}
                    key={k}
                  />
                ))}
              </div>
            ))
        )}

        {($chatOrders.hasMore && !$chatOrders.loading) && (
          <EarlierMessagesBtn>
            <span onClick={() => $chatOrders.paginate()}>Загрузить ещё</span>
          </EarlierMessagesBtn>
        )}

        {$chatOrders.loading && <LinearProgress/>}
      </CardsWrapper>
    </Container>
  );
});
