import React from "react";
import { Box} from "@mui/material";
import { Modal } from "ui-components/modal";
import { useState } from "react";
import { $modals } from "store/modals";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";
import { $chatOrders } from "store/chat/orders";

const ReturnOrderToShopModal = observer(() => {
  const orderId = $modals.params;

  const [btnLoading, setBtnLoading] = useState(false);

  const onSubmit = () => {
    setBtnLoading(true);
    $chatOrders
      .returnToShopOrder(orderId)
      .then(() => $modals.close())
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal title="Возврат заказа в магазин" subheader={<>
      Подтвердите возврат заказа <b>№{orderId}</b> в ПВЗ.
    </>}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <LoadingButton
          onClick={onSubmit}
          loading={btnLoading}
          variant="contained"
        >
          Вернуть в ПВЗ
        </LoadingButton>
      </Box>
    </Modal>
  );
});

export default ReturnOrderToShopModal;
